<template>
  <div v-for="(v, index) in $attrs.data || []" :key="index">
    <div
      class="flex-row align-center"
      style="border-radius: 5px; padding: 4px; cursor: pointer"
      @click="() => $attrs.select_id(v)"
    >
      <div
        class="icon"
        :style="`background-image: url(${
          v.children && v.children.length
            ? !$attrs.params[v.id]
              ? require('/src/assets/shouqi.png')
              : require('/src/assets/zhankai.png')
            : ''
        });margin-left: ${v.level - 2}rem`"
      />
      <div
        class="flex1"
        :style="`color: ${!!$attrs.params[v.id] ? '#FCA307' : 'black'};`"
      >
        {{ v.catalogName }}
      </div>
    </div>
    <Directory
      :data="v.children"
      :params="$attrs.params"
      :select_id="(v) => $attrs.select_id(v)"
      v-if="!!$attrs.params[v.id]"
    />
  </div>
</template>

<script >
export default {
  name: "Directory",
};
</script>

<style scoped lang="less">
.icon {
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>