<template>
  <div class="container flex-column" style="min-height: 100vh">
    <div class="header p_sticky">
      <div class="wrap">
        <div class="title flex-row flex-center">
          <router-link to="/" class="home">
            <img :src="require('../assets/logo.jpg')" class="logo" />
            <span class="title-text">
              中医临床药学服务<br />与调剂教育平台
            </span>
          </router-link>
          <ul class="nav-menu flex-row justify-evenly flex1">
            <li
              v-for="(item, index) in links"
              :key="index"
              :class="{ active: item.active }"
            >
              <router-link
                :to="item.url"
                :class="{ active: item.url === '/knowledge' }"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
          <Avatar style="margin-right: 37px;" />
        </div>
      </div>
    </div>
    <Search />
    <div class="main">
      <div class="main_top">
        <router-link class="pointer" to="/knowledge" >知识库</router-link> / 
        <span style="color: #fcac27">{{ directory_data.catalogName }}</span>
      </div>
      <div class="main_bottom">
        <div class="list flex-column">
          <div class="list_head flex-row">
            <div>目录</div>
            <div class="list_head_name line_ellipsis flex1">
              {{ directory_data.catalogName }}
            </div>
          </div>
          <div class="list_content">
            <div
              style="margin-bottom: 10px"
              v-for="(v, index) in directory_list"
              :key="index"
            >
              <div
                class="flex-row align-center"
                :style="`border-radius: 5px; padding: 4px; background: ${
                  selectmap[v.id] ? '#FEF2DE' : '#F5F5F4'
                }; cursor: pointer;margin: ${selectmap[v.id] ? '6px 0' : '0'};`"
                @click="() => select_id(v)"
              >
                <div style="width: 1rem">
                  <div
                    :style="`width: 6px; height: 6px; border-radius: 50%; background-color: ${
                      selectmap[v.id] ? '#FCA307' : 'black'
                    };`"
                  />
                </div>
                <div
                  class="flex1"
                  :style="`color: ${selectmap[v.id] ? '#FCA307' : 'black'};`"
                >
                  {{ v.catalogName }}
                </div>
              </div>
              <Directory
                :data="v.children"
                :params="selectmap"
                :select_id="(v) => select_id(v)"
                v-if="!!selectmap[v.id]"
              />
            </div>
          </div>
        </div>
        <div class="content">
          <div class="content_title">{{ article_content.title }}</div>
          <div class="ck-content" v-html="article_content.content || ''"></div>
        </div>
        <div class="recommend">
          <div class="recommend_top flex-row">
            <div class="flex-row align-center">
              <div class="orange_div" />
              &nbsp;推荐内容
            </div>
            <div class="flex1" style="text-align: right">
              <div
                class="button_div"
                :style="`background-image: url(${require('/src/assets/left.png')}); margin-right: 30px;`"
                @click="prev"
              ></div>
              <div
                class="button_div"
                :style="`background-image: url(${require('/src/assets/right.png')})`"
                @click="next"
              ></div>
            </div>
          </div>
          <div class="recommend_mid">
            <div
              class="page"
              v-for="(v, index) in recommend_list"
              :key="index"
              :style="`margin-left: ${index == 0 ? 1 - recommend_key : 0}00%`"
            >
              <div
                class="article"
                v-for="(v1, index1) in v || []"
                :key="index1"
                @click="() => get_directory(v1)"
              >
                <div class="article_title line_ellipsis">
                  {{ v1.catalogName }}
                </div>
                <div class="article_content lines_ellipsis">
                  {{ v1.catalogIntro }}
                </div>
              </div>
            </div>
          </div>
          <div class="recommend_bot flex-row justify-center">
            <div
              v-for="(v, index) in recommend_list"
              :key="index"
              :class="`dot ${recommend_key == index + 1 ? 'select' : 'normal'}`"
              @click="recommend_key = index + 1"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { reactive, toRefs, onMounted } from "vue";
import Directory from "../components/Directory.vue";
import { post } from "@/utils/common";
import { $msg } from "../main";
import { useRoute } from "vue-router";

export default {
  name: "KnowSearch",
  components: {
    Directory,
  },
  setup(props) {
    const route = useRoute();
    const state = reactive({
      recommend_list: [],
      recommend_key: 1,
      datamap: {},
      selectmap: {},
      list_level: 1,
      first_id: "",
      directory_data: {},
      directory_list: [],
      article_content: {},
      loading: 0,
    });
    onMounted(() => {
      get_directory(route.query);
    });
    const prev = () => {
      const length = (state.recommend_list || []).length;
      state.recommend_key =
        state.recommend_key - 1 < 1 ? length : state.recommend_key - 1;
    };
    const next = () => {
      const length = (state.recommend_list || []).length;
      state.recommend_key =
        state.recommend_key + 1 > length ? 1 : state.recommend_key + 1;
    };
    const search_id = (directory_list, parent, level) => {
      directory_list.forEach((v) => {
        state.datamap[v.id] = v;
        v.pid = parent ? parent.id : undefined;
        v.level = level;
        v.children && search_id(v.children, v, level + 1);
      });
    };
    const select_id = ({ id, knowledgeBaseId, status, children = [] }) => {
      const { datamap, selectmap } = state;
      let obj = {};

      if (!children.length) {
        get_article(id);
        get_recommend({ id, knowledgeBaseId });
      }

      if (selectmap[id] && !status) {
        state.selectmap[id] = !state.selectmap[id];
        return false;
      }
      if (datamap[id]) {
        while (true) {
          obj[id] = true;
          if (!datamap[id].pid) {
            break;
          } else {
            id = datamap[id].pid;
          }
        }
        state.selectmap = obj;
      }
      window.scroll(0, 0);
    };
    const get_directory = ({ id, knowledgeBaseId }) => {
      state.loading += 1;
      $msg.loading = !!state.loading;
      post(
        "WebKnowledgeBase/getKnowledgeBaseCatalog",
        { id: knowledgeBaseId },
        (data, res) => {
          state.directory_data = res.body.data[0] || {};
          state.directory_list =
            (res.body.data[0] && res.body.data[0].children) || [];

          search_id(state.directory_list, undefined, state.list_level);

          if (!id) {
            for (let [key, value] of Object.entries(state.datamap)) {
              if (!value.children) {
                select_id(value);
                break;
              }
            }
          } else {
            select_id({ id, knowledgeBaseId, status: true });
          }

          state.loading -= 1;
          $msg.loading = !!state.loading;
        },
        (err) => {
          state.directory_data = {};
          state.directory_list = [];
          state.loading -= 1;
          $msg.loading = !!state.loading;
        }
      );
    };
    const get_article = (id) => {
      state.loading += 1;
      $msg.loading = !!state.loading;
      post(
        "WebKnowledgeBase/getContent",
        { id },
        (data, res) => {
          state.article_content = res.body.data || {};
          state.loading -= 1;
          $msg.loading = !!state.loading;
        },
        (err) => {
          state.article_content = {};
          state.loading -= 1;
          $msg.loading = !!state.loading;
        }
      );
    };
    const get_recommend = (params) => {
      state.loading += 1;
      $msg.loading = !!state.loading;
      post(
        "knowledgeBase/querySimilarityByDocInWeb",
        { ...params },
        (data, res) => {
          const articleData = res.body.data || [];
          let list = [],
            arr = [];
          articleData.forEach((v, index) => {
            arr.push(v);
            if ((index + 1) % 3 === 0) {
              list.push(arr);
              arr = [];
            }
            if (index + 1 == articleData.length && !!arr.length) {
              list.push(arr);
              arr = [];
            }
          });
          state.recommend_list = list;
          state.loading -= 1;
          $msg.loading = !!state.loading;
        },
        (err) => {
          state.recommend_list = [];
          state.loading -= 1;
          $msg.loading = !!state.loading;
        }
      );
    };
    return {
      ...toRefs(state),
      prev,
      next,
      select_id,
      get_directory,
    };
  },
};
</script>

<style lang="less" scoped>
@default_text_color: #666;
@default_bg_color: #fff;
@select_color: #fca307;
@default_radius: 5px;

.container {
  max-width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
}

.know_header {
  max-width: 100vw;
  object-fit: cover;
}

.line_ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.lines_ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fix_head {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1;
}

.main {
  width: 85%;
  margin: 0 auto;
  margin-top: 1vw;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .main_top {
    line-height: 3em;
    font-weight: bold;
    color: @default_text_color;
    padding: 0px 1vw;
    border-radius: @default_radius;
    overflow: hidden;
    background: @default_bg_color;
  }

  .main_bottom {
    margin: 1vw 0;
    align-items: flex-start;
    flex-grow: 1;
    display: flex;
    flex-direction: row;

    > div {
      background: @default_bg_color;
      border-radius: @default_radius;
      margin-left: 20px;
    }
    .list {
      flex: 2;
      margin-left: 0;
      width: 10px;
      position: sticky;
      top: 74px;
      max-height: calc(100vh - 97px);
      padding: 10px;

      .list_head {
        margin: 10px 0;
        font-weight: bold;
        color: #fca307;

        .list_head_name {
          width: 10px;
          margin-left: 40px;
        }
      }
      .list_content {
        flex: 1;
        overflow: auto;
      }
    }
    .content {
      flex: 5;
      width: 10px;
      padding: 20px 10px;
      align-self: stretch;

      .content_title {
        font-size: 24px;
        font-weight: bold;
        padding-bottom: 20px;
        border-bottom: 1px solid #d6d3d1;
        margin-bottom: 20px;
      }
      .ck-content{
        user-select: none;
      }
    }
    .recommend {
      flex: 2;
      width: 10px;
      position: sticky;
      top: 74px;

      .recommend_top {
        margin: 10px 20px;

        .orange_div {
          width: 4px;
          height: 1rem;
          background-color: #fca307;
        }
        .button_div {
          width: 24px;
          height: 24px;
          display: inline-block;
          background-repeat: no-repeat;
          background-size: cover;
          cursor: pointer;
        }
      }
      .recommend_mid {
        margin: 0 30px 0 20px;
        white-space: nowrap;
        overflow: hidden;

        .page {
          display: inline-block;
          white-space: normal;
          transition: all 0.3s;
          width: 100%;
          vertical-align: top;

          .article {
            margin-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #d6d3d1;
            cursor: pointer;
            line-height: 1.6rem;

            .article_title {
              font-weight: bold;
            }
            .article_content {
              -webkit-line-clamp: 3;
              font-size: 14px;
              color: #999;
            }
          }
          .article:first-child {
            margin-top: 0;
          }
          .article:last-child {
            padding-bottom: 0;
            border-bottom: 1px solid #fff;
          }
          .article:hover {
            .article_title {
              text-decoration: underline;
            }
          }
        }
      }
      .recommend_bot {
        margin: 10px;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-left: 10px;
          cursor: pointer;
        }
        .dot:first-child {
          margin-left: 0;
        }
        .normal {
          border: 1px solid gray;
        }
        .select {
          border: 1px solid #fca307;
          background-color: #fca307;
        }
      }
    }
  }
}

.search {
  width: 100%;
  position: absolute;
  bottom: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search_main {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .input_d {
      width: 40vw;
      height: 45px;
      border-radius: 20px;
      margin-bottom: 15px;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        padding-left: 30px;
        font-size: 18px;
      }

      input::-webkit-input-placeholder {
        color: #999;
        font-weight: bold;
      }
      input::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #999;
        font-weight: bold;
      }
      input:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #999;
        font-weight: bold;
      }
      input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #999;
        font-weight: bold;
      }
    }

    .search_keys {
      bottom: -20px;
      display: flex;
      font-size: 14px;
      font-family: SimHei;
      font-weight: bold;
      color: #666666;
      padding-left: 30px;

      .search_keey_item {
        margin-right: 20px;
      }
    }
  }
}
</style>
